import React from 'react'
import {
  Card,
  Button,
  CardContent,
  CardMedia,
  Typography,
  Box
} from '@mui/material'

import Markdown from 'react-markdown'
import gfm from 'remark-gfm'
import { navigate } from 'gatsby'
import { stringify } from 'query-string'

import { buildImageLink, formatPrice } from '../../helpers'
import { Price, TextContainer, ButtonContainer } from './Styled'
import { routePaths } from '../../constants/routes'
import { ImagesContainer } from '../common/Styled'

interface ToOrderCardProps {
  strapi_id: number
  images?: { url: string; alternativeText: string }[]
  title: string
  description: string
  price: number
}

const ToOrderCard = (props: ToOrderCardProps) => {
  const { strapi_id, title, price, description, images } = props

  return (
    <Card
      sx={{
        width: '350px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ImagesContainer hasSecondPicture={!!images?.[1]}>
        {images?.[0] && (
          <CardMedia
            component="img"
            className="first-picture"
            sx={{}}
            alt={images[0].alternativeText}
            height="300"
            image={buildImageLink(images[0].url)}
            title={images[0].alternativeText}
          />
        )}
        {images?.[1] && (
          <CardMedia
            component="img"
            className="second-picture"
            alt={images[1].alternativeText}
            height="300"
            image={buildImageLink(images[1].url)}
            title={images[1].alternativeText}
          />
        )}
      </ImagesContainer>
      <CardContent
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography
            align="center"
            gutterBottom
            color="textSecondary"
            variant="body1"
            component="div"
            whiteSpace="pre-line"
          >
            <Markdown remarkPlugins={[gfm]}>{description}</Markdown>
          </Typography>
        </Box>
        <Box>
          <TextContainer>
            <Typography variant="h5">A partir de</Typography>
            <Price align="center" variant="h5" color="primary">
              {formatPrice(price)}
            </Price>
          </TextContainer>
          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(
                  `${routePaths.contact}?${stringify({ id: strapi_id })}`
                )
              }
            >
              Contactez Nous
            </Button>
          </ButtonContainer>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ToOrderCard
