import { Box, Typography } from "@mui/material";
import { styled, css } from "@mui/material/styles";

export const TextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const Price = styled(Typography)`
  font-weight: bold;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
