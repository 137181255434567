import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { DesignServices, Home } from '@mui/icons-material'
import { Typography, Pagination, Stack } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../components/common/seo'
import { routePaths } from '../constants/routes'
import Header from '../components/common/Header'
import { PaginationContainer } from '../components/ProductsList/Styled'
import ToOrderCard from '../components/ToOrderCard'
import { SubContainer } from '../components/common/Styled'

const LIMIT = 9

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    toOrder: strapiToOrder {
      strapi_id
      referencing {
        metaTitle
      }
      products {
        strapi_id
        title
        description
        price
        images {
          strapi_id
          url
          alternativeText
          width
          height
        }
      }
    }
  }
`

const ToOrder = () => {
  const [page, setPage] = useState(1)

  const { toOrder, home } = useStaticQuery(query)

  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    e.stopPropagation()
    // eslint-disable-next-line no-unused-expressions
    typeof window !== 'undefined' && window.scrollTo(0, 0)
    setPage(page)
  }

  const title = toOrder.referencing.metaTitle

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <DesignServices />,
      label: title
    }
  ]

  return (
    <>
      <SEO title="Sur commande" />
      <SubContainer>
        <Header {...{ items, title }} />
        <div
          style={{
            maxWidth: ' 1110px',
            margin: 'auto'
          }}
        >
          <div
            style={{
              margin: '25px 0',
              display: 'flex',
              gap: '30px',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {isEmpty(toOrder.products) ? (
              <Stack>
                <Typography>Aucun resultat</Typography>
              </Stack>
            ) : (
              toOrder.products
                .filter((product, index: number) => {
                  const start = (page - 1) * LIMIT
                  const end = LIMIT * page
                  return index >= start && index < end
                })
                .map(({ strapi_id, title, price, images, description }) => (
                  <ToOrderCard
                    key={strapi_id}
                    {...{ strapi_id, title, price, images, description }}
                  />
                ))
            )}
          </div>
          {Math.ceil(toOrder.products.length / LIMIT) > 1 && (
            <PaginationContainer>
              <Pagination
                count={Math.ceil(toOrder.products.length / LIMIT)}
                onChange={handleChangePage}
                page={page}
                color="primary"
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </PaginationContainer>
          )}
        </div>
      </SubContainer>
    </>
  )
}
export default ToOrder
